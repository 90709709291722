import React from 'react'
import { Outlet, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import '../css/nosotros.css'
import Footer from '../components/Footer'

import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'
import Slider from '../components/sliders/SlideNosotros'
import Timeline from '../components/TimeLine'




  
export default function Nosotros() {
    const { t } = useTranslation('nosotros');
    //Slide 
    const slides = [
        <img src="../images/nosotros/nosotros-HEADER01.webp" alt="Slide 1" />,
        <img src="../images/nosotros/nosotros-HEADER02.webp" alt="Slide 2" />,
        <img src="../images/nosotros/nosotros-HEADER04.webp" alt="Slide 4" />,
        <img src="../images/nosotros/nosotros-HEADER05.webp" alt="Slide 5" />,
        <img src="../images/nosotros/nosotros-HEADER07.webp" alt="Slide 7" />,
        <img src="../images/nosotros/nosotros-HEADER08.webp" alt="Slide 8" />,
        <img src="../images/nosotros/nosotros-HEADER09.webp" alt="Slide 9" />,
        <img src="../images/nosotros/nosotros-HEADER10.webp" alt="Slide 10" />,
        <img src="../images/nosotros/nosotros-HEADER11.webp" alt="Slide 11" />,        
        <img src="../images/nosotros/nosotros-HEADER13.webp" alt="Slide 13" />,
        <img src="../images/nosotros/nosotros-HEADER14.webp" alt="Slide 14" />,
        // Agrega más slides según sea necesario
      ];
    
  return (
    <>

        <section style={{paddingBottom:'6rem'}}>
            <div className='slider_nosotros'>            
                <Slider slides={slides} />
                <div className="header_text_nosotros">
                    <h2>{t('header_text_nosotros_h2')} </h2>
                    <p>{t('header_text_nosotros_p')} </p> 
                </div>
            </div>
        </section>

        <section>
            <div className='container'>
                <div className='mision_vision_nosotros'>
                    <div className='box_mision_vision_nosotros'>
                        <div className='box_icon_mision_vision_nosotros'>
                            <div className='icon_mision_vision_nosotros'><i class="fi fi-bs-flag"></i></div>
                        </div>
                        <h3>{t('mision_h3')} </h3>
                        <p>{t('mision_p')}</p>
                    </div>
                    <div className='box_mision_vision_nosotros'>
                        <div className='box_icon_mision_vision_nosotros'>
                            <div className='icon_mision_vision_nosotros'><i class="fi fi-br-binoculars"></i></div>
                        </div>
                        <h3>{t('vision_h3')}</h3>
                        <p>{t('vision_p')}</p>
                    </div>
                    <div className='box_mision_vision_nosotros'>
                        <div className='box_icon_mision_vision_nosotros'>
                            <div className='icon_mision_vision_nosotros'><i class="fi fi-ss-bullseye-arrow"></i></div>
                        </div>
                        <h3>{t('proposito_h3')}</h3>
                        <p>{t('proposito_p')}</p>
                    </div>
                    
                </div>
            </div>
        </section>
    
  
        <section className='valores_nosotros'>
            <div className='container'>
            <div className='title_nosotros'>
                    <h2>{t('valores_nosotros_h2')}</h2>
                </div>
                <div className='valores_bf'>
                    <div className='box_valores_bf'>
                        <h3>{t('box_valores_bf_h3')}</h3>
                        <ul>
                            <li>{t('box_valores_bf_li1')}</li>
                            <li>{t('box_valores_bf_li2')}</li>
                            <li>{t('box_valores_bf_li3')}</li>
                            <li>{t('box_valores_bf_li4')}</li>
                            <li>{t('box_valores_bf_li5')}</li>
                        </ul>
                    </div>
                    <div className='box_valores_bf'>
                        <h3>{t('box_valores_bf_h3_1')}</h3>
                        <ul>
                            <li>{t('box_valores_bf_li6')}</li>
                            <li>{t('box_valores_bf_li7')}</li>
                            <li>{t('box_valores_bf_li8')}</li>                            
                        </ul>
                    </div>
                </div>
                <div className='valores_bf'>
                    <div className='box_valores_bf'>
                    <h3>{t('box_valores_bf_h3_2')} </h3>
                        <ul>
                            <li>{t('box_valores_bf_li9')}</li>
                            <li>{t('box_valores_bf_li10')}</li>
                            <li>{t('box_valores_bf_li11')}</li>
                            <li>{t('box_valores_bf_li12')}</li>
                            <li>{t('box_valores_bf_li13')}</li>
                        </ul>
                    </div>
                    <div className='box_valores_bf'>
                        <h3>{t('box_valores_bf_h3_3')}</h3>
                        <ul>
                            <li>{t('box_valores_bf_li14')}</li>
                            <li>{t('box_valores_bf_li15')}</li>
                        </ul>

                    </div>
                </div>

            </div>
        </section>


        <section>
            <div className='numeralia'>
                <div className='container'>
                    <div className='numeralia_content'>
                        <div className='numeralia_row'>
                            <div className='numeralia_box'>
                                <label>{t('numeralia_box_label')}</label>
                                <p>{t('numeralia_box_p')}</p>
                                <label>{t('numeralia_box_label2')}</label>
                            </div>
                            <div className='numeralia_box'>
                                <label></label>
                                <p>{t('numeralia_box2_label')}<br /> {t('numeralia_box2_p')}</p>
                                <label>{t('numeralia_box2_label2')}</label>
                            </div>
                            <div className='numeralia_box'>
                                <label>{t('numeralia_box3_label')}</label>
                                <p>{t('numeralia_box3_p')} <br /> {t('numeralia_box3_p2')} </p>
                                <label>{t('numeralia_box3_label2')}</label>
                            </div>
                        </div>
                        <div className='numeralia_row'>
                            <div className='numeralia_box'>
                                <label>{t('numeralia_box4_label')}</label>
                                <p>{t('numeralia_box4_p')} <br />{t('numeralia_box4_p2')}</p>
                                <label>{t('numeralia_box4_label2')}</label>
                            </div>
                            <div className='numeralia_box'>
                                <label></label>
                                <p>{t('numeralia_box5_label')}<br />{t('numeralia_box5_p')} </p>
                                <label>{t('numeralia_box5_label2')}</label>
                            </div>
                            <div className='numeralia_box'> 
                                <label></label>                               
                                <p>{t('numeralia_box6_label')}  <br />{t('numeralia_box6_p')} </p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        <section>
           <div className='linea_tiempo'>
            <div className='container'>
                <Timeline />
            </div>
           </div>
        </section>
        <section>
            <div className='codigo_etica'>
                <div className='container'>
                    <div className='codigo_etica_box'>
                        <h2> <strong>{t('codigo_etica_h2')}</strong>  {t('codigo_etica_h2_2')}</h2>
                        <p>{t('codigo_etica_p')} </p>

                        <div class="box_content-btn"><a className="navbar-link" href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/" target="_blank" rel="noopener noreferrer">{t('codigo_etica_btn')}</a></div>
                    </div>
                </div>
            </div>
           
        </section>
        <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title1')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title1')}  </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/tarifas">{t('cta_btn1')}</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>{t('cta1_title2')}</h4>
                                </div>
                                <div>
                                    <p>{t('cta_title2')}</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">{t('cta_btn2')}</Link>     
                            </div>
                        </div>
                    </div>
                    {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envíanos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación, nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn3')}</a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        {/*
        <section className='container'>
            <LogoCarousel logos={logos} />
        </section>
        */}
        <Footer />   
         <Outlet />
    </>
  )
}
