import React, {useState, useEffect} from 'react'
import axios from 'axios';

export default function Participacion() {
    const [fileUnidad, setFileUnidad] = useState(false);
    const [filePase, setFilePase] = useState(false);
    const [participacionOk, setParticipacionOk] = useState(false);
    const [user_id, setUser_id] = useState('');
    const data = new FormData();  
   
    const [formParticipacion, setFormParticipacion] = useState({
            usuario_id: user_id,
            promocion_id: '1',
            respuesta: '',
            numero_pase: '',
            fecha_pase: '',
            foto_pase: null,
            numero_unidad: '',
            fecha_unidad: '',
            foto_unidad: null
        });

    useEffect(() => {
        const userId = localStorage.getItem('userId');
        setUser_id(userId);
        console.log("userId: ", userId);
        setFormParticipacion(prevState => ({
            ...prevState,
            usuario_id: user_id
        }));
    }, [user_id]);

    // Manejo de cambios en los campos de texto
    const handleParticipacion = (e) => {
        const { name, value } = e.target;
        setFormParticipacion((prevState) => ({
            ...prevState,
            [name]: value,
            ...(name === "numero_pase" && { numero_unidad: value }),
            ...(name === "fecha_pase" && { fecha_unidad: value }),
        }));
                    

    };
 
    const participacionData = async (e) => {
        e.preventDefault();
              
        data.append("usuario_id", formParticipacion.usuario_id);
        data.append("promocion_id", formParticipacion.promocion_id);
        data.append("respuesta", formParticipacion.respuesta);
        data.append("numero_pase", formParticipacion.numero_pase);
        data.append("fecha_pase", formParticipacion.fecha_pase);
        data.append("numero_unidad", formParticipacion.numero_unidad);
        data.append("fecha_unidad", formParticipacion.fecha_unidad);
    
        if (formParticipacion.foto_pase) {
            data.append("foto_pase", formParticipacion.foto_pase);
        }
    
        if (formParticipacion.foto_unidad) {
            data.append("foto_unidad", formParticipacion.foto_unidad);
        }
    
        console.log("📤 Enviando FormData:");

        for (let pair of data.entries()) {
            console.log(pair[0], pair[1]);
        }

        await participacion(data.respuesta);
    
    }

    const handleFileChange = async (e) => {
        e.preventDefault();
        const { name, files } = e.target;

        // Update form data
        setFormParticipacion(prevData => ({
            ...prevData,
            [name]: [...(prevData[name] || []), ...files], // Agregar archivos al arreglo existente            
            [name]: files[0],
        }));

       // Actualizar el estado dependiendo del input (file_unidad o foto_pase)
    if (name === 'foto_unidad') {
        setFileUnidad(true);} 
     if (name === 'foto_pase') {
        setFilePase(true);
    }

    };

    const participacion = async () => {

        const data = new FormData();

        Object.entries(formParticipacion).forEach(([key, value]) => {
            data.append(key, value);
        });


        console.log("Enviando2 :", data.get("usuario_id"));
        console.log("Enviando2 :", data.get("promocion_id"));
        console.log("Enviando2 :", data.get("respuesta"));
        console.log("Enviando2 :", data.get("numero_pase"));
        console.log("Enviando2 :", data.get("fecha_pase"));
        console.log("Enviando2 :", data.get("numero_unidad"));
        console.log("Enviando2 :", data.get("fecha_unidad"));
        console.log("Enviando2 :", data.get("foto_pase"));
        console.log("Enviando2 :", data.get("foto_unidad"));           
    
        try {
                    
            const response = await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/participacion', data, {            
           // const response = await axios.post('http://localhost:3001/participacion', data, {            
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Respuesta del servidor:', response.data);
            
            if(response.data.error === false && response.data.status === 201){
                console.log('error:', response.data.error);
                console.log('status:', response.data.status);
                setParticipacionOk(true)
              }

        } catch (error) {
            console.error('Error al enviar los datos:', error);
        }
    };
        


  return (
<>
    {participacionOk ? (
        <div className='box-confirmacion-abordo'>
            <div className='box-confirmacion-abordo-icon'>
                <i class="fi fi-rs-check-circle"></i>
            </div>
            <div className='box-confirmacion-abordo-box'>
                <p>¡Ya estás participando en la promoción de <strong>Baja Ferries</strong> para participar por increíbles premios!</p>
            </div>
            <div className='box-confirmacion-abordo-box'>
                <p>Te hemos enviado un correo con tu código de participación <label>1234567-ABC</label>.</p>
            </div>
            <div className='box-confirmacion-abordo-box'>
                <p>En caso de ser un posible ganador, te contactaremos por medio del teléfono y correo electrónico.</p>
            </div>
        </div>
        ):(
        <form form onSubmit={participacionData} encType="multipart/form-data">
            <div class="form-abordo-group-registro">
                <div className='form-abordo-box'>
                    <p>¿Cuántos barquitos hay en la urna?</p>
                    <input type="number"
                    name='respuesta'
                    placeholder="0"
                    value={formParticipacion.respuesta}
                    onChange={handleParticipacion} />
                </div>
                <div className='form-abordo-box'>
                    <p>Número de reserva</p>
                    <input type="text"
                    name='numero_pase'
                    placeholder="0000000"
                    value={formParticipacion.numero_pase}
                    onChange={handleParticipacion} />
                </div>
            </div>
            <div class="form-abordo-group-registro">
                <div className='form-abordo-box'>
                    <p>Fecha de salida</p>
                    <input type="date"
                    name='fecha_pase'
                    placeholder="0"
                    value={formParticipacion.fecha_pase}
                    onChange={handleParticipacion} />
                </div>
            </div>
            <div class="form-abordo-group-registro">
                <div className='form-abordo-box'>                                                                 
                    <div className='form_input_img'>
                        <label>Sube una foto de tu pase<br />  de abordar:</label>
                        <label for="foto_pase"  className={`${fileUnidad ? 'file-style abordo upload' : 'file-style abordo'}`}>
                            
                            <i className={`fi ${filePase ? 'fi-rs-check-circle' : 'fi-rs-upload'}`}></i> 
                            <input type="file" id="foto_pase" name='foto_pase' accept="image/*" onChange={handleFileChange } />
                            <p>Introduce la imagen</p>
                        </label>                                        
                    </div>     
                    {/*
                    <label for="foto_pase" class="upload-button">
                        <i class="fi fi-sr-paperclip-vertical" ></i> 
                    </label>                                    
                    <input type="file" id="foto_pase" name="foto_pase" onChange={handleFileChange} accept="image/*" />    
                    */}                                                              
                </div>    
                
                <div className='form-abordo-box'>                                                                                         
                    <div className='form_input_img'>
                        <label>Sube una foto de tu unidad<br />  de carga:</label>
                        <label for="foto_unidad" className={`${fileUnidad ? 'file-style abordo upload' : 'file-style abordo'}`} >                                        
                            <i className={`fi ${fileUnidad ? 'fi-rs-check-circle' : 'fi-rs-upload'}`}></i> 
                            <input type="file" id="foto_unidad" name='foto_unidad' accept="image/*" onChange={handleFileChange } />
                            <p>Introduce la imagen</p>
                        </label>                                        
                    </div>
                    {/*
                    <label for="foto_unidad" class="upload-button">
                        <i class="fi fi-sr-paperclip-vertical" ></i> 
                    </label>                                    
                    <input type="file" id="foto_unidad" name="foto_unidad" onChange={handleFileChange} accept="image/*" />    
                    */}
                </div>
            </div>
            <div class="btn_enviar_formulario_abordo">
                <button type='submit'
                    class="btn_enviar" >
                    Enviar
                </button>
            </div>
        </form>
        )}
    </>
  )
}
