import React from 'react'
import '../../css/promocion.css'
import promo1 from '../../assets/images/promociones/abordo_al_bolante_cintillo.webp'
import lateralPromo1 from '../../assets/images/promociones/promocion_ninos-cuadrado.webp'
import lateralPromo2 from '../../assets/images/promociones/inapam.webp'

import { Outlet, Link } from "react-router-dom";
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import Footer from '../../components/Footer'
import { useTranslation } from 'react-i18next';

export default function Promo8() {
//    const targetDate = new Date('2024-09-30T23:59:59').getTime();
  const { t } = useTranslation('promo8');
  return (
   <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>        
                    <h3>{t('title')}</h3>
                    <p>🚢 {t('description')} 🚛</p>
                </div>                
            </div>
        </div>
    </section> 
    
    <section className='container'>
        <div className='banner-header-promo'>
            <img src={promo1} alt='buen-fin-bajaferries' />
        </div>
    </section>
  
    <section className='sectionPromo_content'>
        <div className='container'>
            <div className='box_promo'>
                <div className='content_text_promo'>
                    <div className='content_promo'>
                        
                       <p>{t('text1')} 🎁🚗 </p>
                       <p>{t('text2')}
                       </p>
                         
                        <ul>
                            <li>{t('text3')}</li>
                            <li>{t('text4')}</li>
                            <li>{t('text5')} 🎯</li>
                        </ul>
                        
                        <div className='content_promo_btn'>         
                            <Link className='cta_promo'  to="/registro-abordo-volante">{t('btn3')}</Link>                                       
                            
                            <a target="_blank" className='cta_promo' rel='noopener noreferrer' href='https://wa.me/5215641590866?text=Hola,%20quiero%20participar'>{t('btn2')} </a>
                            <a className='cta_promo' href="tel:8003377437" rel="noopener noreferrer"><span ><i class="fi fi-sr-headset"></i>Call Center</span></a> 
                        </div>
                    </div>
                    <div className='content_promo condiciones-promo'>                        
                        <p>{t('text7')} <Link to="/promo-abordo/tyc">{t('text8')}.</Link>  </p>                                              
                        <p className='btn-ganadores'> <Link to="/promo-abordo/ganadores">{t('text9')}</Link>  </p>  
                    </div>
                 
                </div>
                <div className='content_contador_promo'>

                    <div className='content_promo'>
                      
                        <h4>{t('text6')} </h4>
                        <div className='lateral-promo'>
                            <Link to="/promo-ninos">
                                <img src={lateralPromo1} alt='promo-ninos-bajaferries' />
                            </Link> 
                        </div>
                        <div className='lateral-promo'>
                            <Link to="/promo-adulto-mayor">
                                <img src={lateralPromo2} alt='transportando-carga-bajaferries' />
                            </Link> 
                        </div>
                        
                        
                    </div>
                    
                </div>
            </div>
        </div>        
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title1')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title1')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/soluciones-logisticas">{t('cta_btn1')}</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title2')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title2')}</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas-carga">{t('cta_btn2')} </Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Contacta a nuestro equipo y asegura tu próximo viaje hoy mismo. Estamos aquí para ayudarte a planificar.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>{t('cta1_title3')}</h4>
                            </div>
                            <div>
                                <p>{t('cta_title3')} </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">{t('cta_btn3')} </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />

    <Footer />
   
   </>
  )
}
