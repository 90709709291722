  import React, {useState} from 'react'
  import Participacion from './Participacion';
import Logintelefono from './Logintelefono';
import { Link } from "react-router-dom";

export default function Login() {
    const [aceptoPoliticas, setAceptoPoliticas] = useState(false);
    const [participacion, setParticipacion] = useState (false);
    const [passwordReset, setpasswordReset] = useState(false);
    const [formLogin, setFormLogin] = useState({
            email: '',
            password: '',
         
        });

    const handleLogin = (e) =>{
        const name = e.target.name;
		const value = e.target.value;	

		//console.log(e.target.name, e.target.value);
		setFormLogin({...formLogin,[name]: value});
    }
    

    const login = async () => {
        try {
         // https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws
         const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/login', {
         // const response = await fetch('http://localhost:3001/login', {
            method: "POST", 
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(formLogin) 
          });
    
          const data = await response.json();
          console.log("Login:", data);
          
          if(data.messages.login === true){
            localStorage.setItem('userId', data.messages.id);  // Guarda el userId en localStorage  
            setParticipacion(true);  
          }
          if(data.messages.login === false){
            //setTelOption(true);
            console.log("login error: " ,data.messages.login);
          }

        } catch (error) {
          console.error("Error al obtener usuarios:", error);
        }
      };  

      const resetPassword = () => {
        setpasswordReset(true);
      }

  return (
  <>
      {participacion ? (
        <Participacion />
      ):(<>
      {passwordReset ? (
        <Logintelefono />
      ):(

        <>
        
        <div className='formulario-abordo-title'>
      <h2>Inicio de sesión</h2>
      </div>
      <div className='form-abordo-group'>
        <p>Correo clectrónico</p>
        <input
            type="text"
            name='email'
            placeholder="correo@mail.com"
            value={formLogin.email}
            onChange={handleLogin} />
      </div>

      <div  className='form-abordo-group'>
        <div>
            <p>Contraseña</p>
            <input type="password"
                name='password'
                placeholder="Contraseña"
                value={formLogin.password}
                onChange={handleLogin} />
          </div>

      </div>
      <div className='chackbox_text'>
        
        <label onClick={resetPassword}>¿Olvidaste tu contraseña?</label>                
      </div>                                               
      <div class="btn_enviar_formulario_abordo">                        
        <button
            class="btn_enviar"
            disabled={!aceptoPoliticas}
            onClick={login}>
            Iniciar sesión
        </button>
      </div>

      <div className='chackbox_text'>
        <input type="checkbox" name="" checked={aceptoPoliticas} onChange={() => setAceptoPoliticas(!aceptoPoliticas)} /> Acepto el <label><Link to="/aviso-de-privacidad">Aviso de privacidad</Link> </label> y <label><Link to="/promo-abordo/tyc">Términos y condiciones</Link> </label>. 
      </div>
      </>
      )

      }
      
    </>)}
        
  </>
        
  )
}


