import React from 'react'
import ganadores from '../assets/images/promociones/GANADORES_web.png'
import ganadoresPhone from '../assets/images/promociones/GANADORES_webmovil.webp'
import reservacion from '../assets/images/cta/reservacion.svg'
import preguntas from '../assets/images/pregutas.png'
import tarifas from '../assets/images/cta/tarifas.svg'
import { Outlet, Link } from "react-router-dom";
import Footer from '../components/Footer';
export default function Ganadores() {
  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Abordo y al volante  </h3>
                    <p></p>
                </div>                
            </div>
        </div>
    </section>

    <section style={{paddingTop:'6rem'}}>
        <div className='container'>
            <div className='d-none d-sm-none d-md-block'>
                <img src={ganadores} alt='baja ferries hero' />
            </div>
            <div className='d-block d-sm-block d-md-none'>
                <img src={ganadoresPhone} alt='baja ferries hero' />
            </div>
        </div>
    </section>
        
         <section className="callToAction">
                    <div className='container'>
                        <div>
                            <h3> </h3>
                        </div>
                        <div className='callToAction_row'>
                            <div className='callToAction_container'>
                                <div className='callToAction_icon'>
                                    <img src={tarifas} alt="bookingIcon" className='bookingIcon' />
                                </div>
                                <div className='callToAction_content'>
                                    <div className='callToAction_box'>
                                        <div>
                                            <h4>Consulta nuestras tarifas</h4>
                                        </div>
                                        <div>
                                            <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                                        </div>
                                    </div>
                                    <div className='callToAction_box'>
                                        <Link to="/tarifas">Ver tarifas</Link>     
                                    </div>
                                </div>
                            </div>
                            <div className='callToAction_container'>
                                <div className='callToAction_icon'>
                                    <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                                </div>
                                <div className='callToAction_content'>
                                    <div className='callToAction_box'>
                                        <div>
                                            <h4>Preguntas frecuentes</h4>
                                        </div>
                                        <div>
                                            <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                                        </div>
                                    </div>
                                    <div className='callToAction_box'>
                                        <Link to="/preguntas-frecuentes">Más información</Link>     
                                    </div>
                                </div>
                            </div>
                           {/*<div className='callToAction_container'>
                                <div className='callToAction_icon'>
                                    <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                                </div>
                                <div className='callToAction_content'>
                                    <div className='callToAction_box'>
                                        <div>
                                            <h4>Envianos un WhatsApp</h4>
                                        </div>
                                        <div>
                                            <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                        </div>
                                    </div>
                                    <div className='callToAction_box'>
                                    <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                                    </div>
                                </div>
                            </div>*/}
                             <div className='callToAction_container'>
                            <div className='callToAction_icon'>
                                <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='callToAction_content'>
                                <div className='callToAction_box'>
                                    <div>
                                        <h4>¿Listo para reservar?</h4>
                                    </div>
                                    <div>
                                        <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                                    </div>
                                </div>
                                <div className='callToAction_box'>
                                <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </section>
        
                <Outlet />
                <Footer />
        
        </>
  )
}
