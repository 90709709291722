import React, {useState, useEffect} from 'react'
import '../../css/form_abordo.css'
import img1 from '../../assets/images/promociones/premios-abordoyalvolante.png'
import img2 from '../../assets/images/promociones/BF-Promo-Web_upleft-label.png'
import Footer from '../Footer';

import Login from '../promo/Login';
import Registro from '../promo/Registro';



export default function Abordo() {
    const [isRegistro, setIsRegistro] = useState(false); 
    const [cantUsuarios, setCantUsuarios] = useState('780');        
       

    const getAllUser = async () => {
        try {
            
            const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/usuarios', {
        //const response = await fetch('http://localhost:3001/usuarios', {
            method: "POST", 
            headers: {
            "Content-Type": "application/json"
            },
            body: JSON.stringify({}) 
        });
    
        const data = await response.json();

            if (Array.isArray(data)) {
                let userCount = String(data.length).padStart(4, "0"); // Asegurar que siempre sea string
                setCantUsuarios(userCount);
            } else {
                console.error("La respuesta no es un array:", data);
                setCantUsuarios("0780"); // También aseguramos que sea string
            }

        // console.log("Usuarios registrados:", data);
        } catch (error) {
        console.error("Error al obtener usuarios:", error);          
        }
    };    

    useEffect(() =>{
        getAllUser();
    },[])

    const toggleMostrarRegistro = () =>{
        setIsRegistro(!isRegistro)
    }

  
/*
    useEffect(() => {
        const { usuario_id, promocion_id, respuesta, numero_pase, fecha_pase, numero_unidad, fecha_unidad, foto_pase, foto_unidad } = formParticipacion;
        console.log("useEffect: ", usuario_id, promocion_id, respuesta, numero_pase, fecha_pase,numero_unidad, fecha_unidad, foto_pase, foto_unidad  );
        if (usuario_id && promocion_id && respuesta && numero_pase && fecha_pase && foto_pase && foto_unidad && numero_unidad && fecha_unidad) {
          participacion();
        }
      });
*/


  return (
    <>
   
    <div className='barra_menu_promo'> </div>

    <section className='form-registro-abordo-volante'>
        <div className='head-line-promo'>
            <div className='container'>
                <div className='head-line-promo-content'>
                    <div className='head-line-promo-box'>
                        <img src={img2} alt='' /> 
                    </div>
                    <div className='numeral-promo'>
                        <label>Total de <br /> participantes</label>
                        <label style={{ display: "flex", gap: "5px" }}>
                        {cantUsuarios.split("").map((digit, index) => (
                            <span
                            key={index}
                            style={{
                                backgroundColor: "#fff",
                                color: "#000",
                                padding: "10px",
                                fontSize: "1.5rem",
                                display: "inline-block",
                                minWidth: "30px",
                                textAlign: "center",
                            }}
                            >
                            {digit}
                            </span>
                        ))}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div className='container'>
           
            <div className='form-abordo'>
                <div className='formulario-abordo-imgs'>
                    <img src={img1} alt='' />                    
                </div>

                {isRegistro ? (
                    <div class="formulario-abordo"> 
                        <div className='formulario-abordo-box-registro'>                      
                            <Registro />   
                            <div className='form-abordo-registro-option'>                                                                      
                                <p>¿Ya tienes cuenta? <label onClick={toggleMostrarRegistro}>Inicia sesión aquí</label></p>
                            </div>                                                                                          
                        </div> 
                    </div>
                
                ) : (

                    <div class="formulario-abordo">                                       
                        <div className='formulario-abordo-box'>                    
                            <Login />
                            
                            <div className='form-abordo-registro-option'>                                                                      
                                <p>Si aún no eres usuario <label onClick={toggleMostrarRegistro}>Regístrate aquí</label></p>
                            </div>
                        </div> 
                    </div>
                
                
                
                )}
               

            </div>

        </div>
    </section>
    <Footer />
    </>
  )
}
