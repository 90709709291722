/* eslint-disable react/jsx-pascal-case */
import React, {useEffect} from 'react'
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom'
import {useLayoutEffect} from 'react';
import ReactGA from 'react-ga';

import TaskForm from './components/TaskForm';
import TaskList from './components/TaskList';

import NoticiasForm from './components/NoticiasForm';
import NoticiasList from './components/NoticiasList';

import NewsletterForm from './components/NewsletterForm';
import NewsletterList from './components/NewsletterList';

import EnviarCorreo from './enviarCorreo';

import Header from './components/Header'
import Home from './pages/Home'
import DestinoLapaz from './components/destinos/Lapaz'
import DestinoMazatlan from './components/destinos/Mazatlan'
import DestinoMochis from './components/destinos/Mochis'
import RutasHorario from './pages/Rutas_horario';
import Tarifas from './pages/Tarifas'
import PrepararViaje from './pages/PrepararViaje'
/*import Antes_viajar from './pages/Antes_viajar';*/
import PreguntasFrecuentes from './pages/PreguntasFrecuentes';
import AntesDeViajar from './components/preguntas_frecuentes/AntesDeViajar'
import Documentacion from './components/preguntas_frecuentes/Documentacion'
import Carga from './components/preguntas_frecuentes/Carga'

import Pagos_facturas from './components/preguntas_frecuentes/Pagos_facturas';
import FaqReservar from './components/preguntas_frecuentes/FaqReservar'
import Flota from './pages/Flota'
import Nosotros from './pages/Nosotros';
import Promo1 from './components/promociones/Promo1';
import Promo2 from './components/promociones/Promo2';
import Promo3 from './components/promociones/Promo3';
import Promo4 from './components/promociones/Promo4';
import Promo5 from './components/promociones/Promo5';
import Promo6 from './components/promociones/Promo6';
import Promo7 from './components/promociones/Promo7';
import Promo8 from './components/promociones/Promo8';
import Promo9 from './components/promociones/Promo9';

import KitComunicacion from './pages/KitComunicacion';
import Sostenibilidad from './pages/Sostenibilidad';
import Contacto from './pages/Contacto';
import Puertos from './pages/Puertos';
import Menu from './pages/Menu';
import Antes_de_viajar from './pages/Antes_de_viajar';
import Contact_form from './components/form/ContactForm'
import Itinerario from './pages/Itinerario';
import Pruebas from './pages/Pruebas';
import Soluciones from './pages/SolucionesLogisticas'
import LoginGobernanza from './pages/LoginGobernanza';
import Gobernanza from './pages/Gobernanza';
import Condiciones from './pages/Condiciones';
import Aviso_privacidad from './pages/Aviso_privacidad';
import Destinos from './pages/Destinos';
import Promociones from './pages/Promociones'
import PromocionesCarga from './pages/PromocionesCarga'
import Promociones2 from './pages/Promociones2'
import Noticias from './pages/Noticias';
import Noticia from './pages/Noticia';
import Fleetservice_cali from './components/Fleetservice_cali';
import Fleetservice_mex from './components/Fleetservice_mex';
import Durante_viaje from './pages/Durante_viaje';
import Despues_viaje from './pages/Despues_viaje';
import Certificaciones from './pages/Certificaciones';
import Maritimo from './pages/Maritimo'
import Terrestre from './pages/Terrestre'
import Multimodal from './pages/Multimodal'
import TarifasCarga from './pages/TarifasCarga';
import Tarifario from './pages/Tarifario'
import Footerprueba from './components/footer/Footerprueba';
import Rutas_pruebas from './pages/Rutas_pruebas'
import FAQList from './pages/FAQList';
import FAQDetail from './pages/FAQDetail'
import DetailAntesDeViajar from './components/preguntas_frecuentes/respuestas/DetailAntesDeViajar';
import DetailCarga from './components/preguntas_frecuentes/respuestas/DetailCarga'
import DetailAutomovil from './components/preguntas_frecuentes/respuestas/DetailAutomovil'
import DetailDocumentacion from './components/preguntas_frecuentes/respuestas/DetailDocumentacion';
import DetailReservar from './components/preguntas_frecuentes/respuestas/DetailReservar';
import DetailNoticiasAvisos from './components/preguntas_frecuentes/respuestas/DetailNoticiasAvisos';
import Noticias_avisos from './components/preguntas_frecuentes/Noticias_avisos';
import DetailPagosFactura from './components/preguntas_frecuentes/respuestas/DetailPagosFactura';
import Rutas_prueba from './pages/Rutas_prueba';
import FooterPrueba from './components/FooterPrueba'
import Itinerarioprueba from './pages/Itinerarioprueba';
import Rutas_horario_carga from './pages/Rutas_horario_carga';
import IntegracionLogistica from './pages/IntegracionLogistica';
import Headerprueba from './pages/Headerprueba';
import Automovil from './components/preguntas_frecuentes/Automovil';
import Ruthorario from './pages/Rutahorario'
import ViajeEnGrupo from './pages/ViajeEnGrupo'
import Reservapruebas from './components/form/Reservapruebas'
import Tyc from './pages/Tyc'
import Aviso from './pages/Aviso';
import ViajeCurso from './pages/ViajeCurso';
import RegistroAyV from './pages/RegistroAyV';
import Ganadores from './pages/Ganadores'
import Abordo from './components/promociones/Abordo';
import Horario_semana_santa from './pages/Horario_semana_santa';

const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  }

const TRACKING_ID = "G-J9XRYFW0E9"; 
ReactGA.initialize(TRACKING_ID);

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, []);

    return (
        <BrowserRouter>
        <Wrapper>
            <Routes>
                <Route path="/" element={<Headerprueba />}>
                    <Route index element={<Home />} />
                    <Route path="rutas_horario" element={<RutasHorario />} />
                    <Route path="rutas-horario-semana-santa" element={<Horario_semana_santa />} />
                    <Route path="rutas-tarifas-y-horarios" element={<RutasHorario />} />
                    <Route path="destino-la-paz" element={<DestinoLapaz />} />
                    <Route path="destino-mazatlan" element={<DestinoMazatlan />} />
                    <Route path="destino-los-mochis" element={<DestinoMochis />} />
                    <Route path="tarifas" element={<Tarifas />} />
                    <Route path="prepara-tu-viaje" element={<PrepararViaje />} />
                    <Route path="antes-de-viajar" element={<Antes_de_viajar />} /> 
                    {/*<Route path="antes-de-viajar" element={<Antes_viajar />} />*/}
                    <Route path="puertos" element={<Puertos />} />
                    <Route path="preguntas-frecuentes" element={<PreguntasFrecuentes />} />                 
                    <Route path="flota" element={<Flota />} />
                    <Route path="/nosotros" element={<Nosotros />} />

                    <Route path="transportando-carga" element={<Promo1 />} />
                    <Route path="promo-mascota" element={<Promo2 />} />
                    <Route path="promo-ninos" element={<Promo3 />} />
                    <Route path="promo-adulto-mayor" element={<Promo4 />} />
                    <Route path="promo-bf-patrio" element={<Promo5 />} />
                    <Route path="promo-acompanante-carga" element={<Promo6 />} />             
                    <Route path="promo-autobus" element={<Promo7 />} />
                    <Route path="promo-abordo" element={<Promo8 />} />
                    <Route path="promo-buen-fin" element={<Promo9 />} />                    
                    
                    <Route path="kit-de-comunicacion" element={<KitComunicacion />} />
                    <Route path="contacto" element={<Contacto />} />
                    <Route path="puertos" element={<Puertos />} />    
                    <Route path="formulario-de-contacto" element={<Contact_form />} />                                                           
                    <Route path="condiciones-de-servicio" element={<Condiciones />} />
                    <Route path="aviso-de-privacidad" element={<Aviso_privacidad />} />
                    <Route path="destinos" element={<Destinos />} />
                    <Route path="promociones" element={<Promociones />} />
                    <Route path="Promociones-carga" element={<PromocionesCarga />} />
                    <Route path="gobernanza" element={<Gobernanza />} />
                    <Route path="noticias" element={<Noticias />} />
                    <Route path="certificaciones" element={<Certificaciones />} />
                    <Route path="servicios-california-star" element={<Fleetservice_cali />} />
                    <Route path="servicios-mexico-star" element={<Fleetservice_mex />} />
                    <Route path="durante_tu_viaje" element={<Durante_viaje />} />
                    <Route path="al-llegar" element={<Despues_viaje />} />
                    <Route path="tarifario" element={<Tarifario />} />
                    <Route path="rutas_pruebas" element={<Rutas_pruebas />} />
                    <Route path="rutas_prueba" element={<Rutas_prueba />} />

                    <Route path="/FAQList" element={<FAQList />} />
                    <Route path="/question/:id" element={<FAQDetail/>} />
                    
                    <Route path="antes-de-viajar-faqs" element={<AntesDeViajar />} />
                    <Route path="/preguntas-frecuentes-antes-viajar/:id" element={<DetailAntesDeViajar/>} />

                    <Route path="carga" element={<Carga />} />
                    <Route path="/preguntas-frecuentes-carga/:id" element={<DetailCarga/>} />

                    <Route path="documentacion" element={<Documentacion />} />    
                    <Route path="/preguntas-frecuentes-documentacion/:id" element={<DetailDocumentacion />} />

                    <Route path="reserva" element={<FaqReservar />} />
                    <Route path="/preguntas-frecuentes-reserva/:id" element={<DetailReservar />} />

                    <Route path="noticias-avisos" element={<Noticias_avisos />} />
                    <Route path="/preguntas-frecuentes-noticias-avisos/:id" element={<DetailNoticiasAvisos />} />

                    <Route path="automovil" element={<Automovil />} />
                    <Route path="/preguntas-frecuentes-automovil/:id" element={<DetailAutomovil/>} />   

                    <Route path="pagos-facturas" element={<Pagos_facturas />} />   
                    <Route path="/preguntas-frecuentes-pagos-facturas/:id" element={<DetailPagosFactura />} />  
                    <Route path="noticia/:id" element={<Noticia />} /> 
                    <Route path="/viaje-en-grupo" element={<ViajeEnGrupo />} />  
                    <Route path="/aviso" element={<Aviso />} />  
                    <Route path="/viaje-en-curso" element={<ViajeCurso />} />
                    <Route path="/promo-abordo/ganadores" element={<Ganadores />} />
                    
                    <Route path="/promo-abordo/tyc" element={<Tyc />} />
                    <Route path="/promo-abordo-tyc" element={<Tyc />} />
                    {/*<Route path="/registro-abordo-volante" element={<RegistroAyV />} />*/}
                    <Route path="/registro-a-bordo-y-al-volante" element={<RegistroAyV />} />
                    <Route path="/registro-abordo-volante" element={<Abordo />} />   

                </Route>    
                <Route path="sostenibilidad" element={<Sostenibilidad />} />            
                <Route path="menu" element={<Menu />} />  
                <Route path="login-gobernanza" element={<LoginGobernanza />} /> 

                <Route path="tasks/new" element={<TaskForm />} />
                <Route path="tasklist" element={<TaskList />} />
                <Route path="tasks/:id/edit" element={<TaskForm />} />  
                
                <Route path="noticias/new" element={<NoticiasForm />} />
                <Route path="noticialist" element={<NoticiasList />} />                
                <Route path="noticias/:id/edit" element={<NoticiasForm />} /> 

                <Route path="newsletter/new" element={<NewsletterForm />} />
                <Route path="newsletterlist" element={<NewsletterList />} />                
                <Route path="newsletter/:id/edit" element={<NewsletterForm />} />  
                 
                <Route path="enviar-correo" element={<EnviarCorreo />} /> 
                <Route path="header" element={<Header />} />  
                <Route path="soluciones-logisticas" element={<Soluciones />} />
                <Route path="maritimo" element={<Maritimo />} /> 
                <Route path="terrestre" element={<Terrestre />} /> 
                <Route path="multimodal" element={<Multimodal />} />   
                <Route path="integracion-logistica" element={<IntegracionLogistica />} /> 
                <Route path="tarifas-carga" element={<TarifasCarga />} /> 
                <Route path="footer-prueba" element={<Footerprueba />} /> 
                <Route path="footerprueba" element={<FooterPrueba />} /> 
                <Route path="itinerarioprueba" element={<Itinerarioprueba />} />
                <Route path="rutas-horario-carga" element={<Rutas_horario_carga />} />
                {/*<Route path="headerprueba" element={<Pruebas/>} />  */}
                <Route path="rutashorario" element={<Ruthorario />} />                            
                <Route path="reservapruebas" element={<Reservapruebas />} />
             

 
                

            </Routes>
            </Wrapper>
        </BrowserRouter>
    );
}

export default App;
