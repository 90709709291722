import React, {useState} from 'react'
import Participacion from './Participacion';

export default function Registro() {
    const [isRegistro, setIsRegistro] = useState(false); 
    const[errorAlert, setErrorAlert] = useState(false);
    const[errorMessage, setErrorMessage] = useState('');
    const [aceptoPoliticas, setAceptoPoliticas] = useState(false);
    const [user_id, setUser_id] = useState(null);
    const [registroOk, setRegistroOk] = useState(false);
    
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        password: '',
        fecha_nac: '',
        telefono: '', 
        origen: 'web'
    });

    const hoy = new Date();
    const hace18Anios = new Date(hoy.getFullYear() - 18, hoy.getMonth(), hoy.getDate());
    const fechaMax = hace18Anios.toISOString().split("T")[0];
    

    const handleAssignment  = e => {		
		const name = e.target.name;
		const value = e.target.value;	

		//console.log(e.target.name, e.target.value);
		setFormData({...formData,[name]: value});
	};	

    const toggleRegistro = async () => {
        setIsRegistro(!isRegistro);
    }

    const registro = async () => {
        //console.log("Registro ", formData);

        try {

            
            const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/registro', {
            //const response = await fetch('http://localhost:3001/registro', {
              method: "POST", 
              headers: {
                "Content-Type": "application/json"
              },

              body: JSON.stringify(formData) 
            });
      
            const data = await response.json();                                             
            console.log("consola: ", data);  
            /*
            console.log("consola2: ", data.messages);  
            console.log("consola3: ", data.messages.password);  
            console.log("consola4: ", data.messages.telefono);  
            console.log("consola5: ", data.messages.email);  */            
            
            if (data.error) {
                // Extraer los mensajes de error
                setErrorAlert(true);
                const errorMessages = Object.values(data.messages).join('\n');
                /*console.log(`Errores:\n${errorMessages}`);*/
                setErrorMessage(errorMessages);
              } else {
                setErrorAlert(false);
                console.log("Registro exitoso:", data);
                localStorage.setItem('userId', data.messages.id);  // Guarda el userId en localStorage           

                console.log("usuario_id: ", data.messages.id);
                setUser_id(data.messages.id);
               
                setRegistroOk(true);
                /*console.log("Registro exitoso!");*/
              }
                      
                 
          } catch (error) {
            console.error("Error al obtener usuarios:", error);
            console.error("Error: ", error.message);
          }
      };  

  return (
    <>
    { registroOk ? (
        <Participacion />
    ) : (
        <>
        <div className='formulario-abordo-title'>
            <h2>Regístrate</h2> 
            <p>Completa todos los campos</p>  
            {errorAlert && (
                <div className='message_error' style={{ whiteSpace: 'pre-line' }}>
                    {errorMessage}.
                </div>                                
            )}                           
        </div>
        <div>                            
                < div class="form-abordo-group-registro">
                    <div className='form-abordo-box'>
                        <p>Nombre (s)</p>
                        <input
                            type="text"
                            placeholder="Jorge"
                            name='nombre'
                            value={formData.nombre}
                            onChange={handleAssignment} />
                    </div>
                    <div className='form-abordo-box'>
                        <p>Apellidos</p>
                        <input
                            type="text"
                            name='apellido'
                            placeholder="Pérez Cuevas"
                            value={formData.apellido}
                            onChange={handleAssignment} />
                    </div>
                </div>

                <div class="form-abordo-group-registro">
                    
                    <div className='form-abordo-box'>
                        <p>Correo clectrónico</p>
                        <input type="email"
                        name='email'
                        placeholder="jorge@correo.com"
                        value={formData.email}
                        onChange={handleAssignment} />
                    </div>   
                    <div className='form-abordo-box'>
                        <p>Contraseña</p>
                        <input type="password"  
                        placeholder='••••••••' 
                        name='password'                             
                        value={formData.password}
                        onChange={handleAssignment} />
                    </div>                              
                </div>

                <div class="form-abordo-group-registro">
                    <div className='form-abordo-box'>
                        <p>Teléfono celular</p>
                        <input type='tel'
                            name='telefono'
                            value={formData.telefono}
                            onChange={handleAssignment} 
                            placeholder="10 dígitos" />                                        
                    </div>
                    <div className='form-abordo-box'>
                        <p>Fecha de nacimiento</p>
                        <input type="date"   
                        name='fecha_nac'                             
                        value={formData.fecha_nac}
                        max={fechaMax}
                        onChange={handleAssignment} />
                    </div>                                
                </div>
                
                
                <div className='chackbox_text registro'>
                    <input type="checkbox" name="" checked={aceptoPoliticas} onChange={() => setAceptoPoliticas(!aceptoPoliticas)} /> Acepto el aviso legal y las política de privacidad. 
                </div>
                <div class="btn_enviar_formulario_abordo">                        
                    <button
                        class="btn_enviar"
                        disabled={!aceptoPoliticas}
                        onClick={registro}>
                        Enviar
                    </button>
                </div>
                                        
        </div>
    </>
    )

    }
    
    </>
  )
}
