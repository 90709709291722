import React, {useState} from 'react'
import Participacion from './Participacion';

export default function Logintelefono() {

     const [aceptoPoliticas, setAceptoPoliticas] = useState(false);        
        const [participacion, setParticipacion] = useState (false);    
        const [formLogin, setFormLogin] = useState({
                email: '',
                telefono: '',
             
            });
    
        const handleLogin = (e) =>{
            const name = e.target.name;
            const value = e.target.value;	
    
            //console.log(e.target.name, e.target.value);
            setFormLogin({...formLogin,[name]: value});
        }
        
    
        const login = async () => {
            try {
             // https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws
                const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/login', {
              //const response = await fetch('http://localhost:3001/login_telefono', {
                method: "POST", 
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(formLogin) 
              });
        
              const data = await response.json();
              console.log("Login con tel:", data);
              console.log("data.success:", data.success);
   
              if(data.success === true){
                localStorage.setItem('userId', data.userId);  // Guarda el userId en localStorage  
                setParticipacion(true);  
                console.log("data.success2:", data.success); 
              }
              if(data.messages.login === false){
                //setTelOption(true);
                console.log("login error: " ,data.messages.login);
              }
    
            } catch (error) {
              console.error("Error al obtener usuarios:", error);
            }
          };  
          
    
  return (
   <>
       {participacion ? (
         <Participacion />
       ):(<>
       <div className='formulario-abordo-title'>
       <h2>Inicio de sesión</h2>
       </div>
       <div className='form-abordo-group'>
         <p>Correo electrónico</p>
         <input
             type="text"
             name='email'
             placeholder="correo@mail.com"
             value={formLogin.email}
             onChange={handleLogin} />
       </div>
 
       <div  className='form-abordo-group'>
       <p>Teléfono</p>
             <input type="tel"
                 name='telefono'
                 placeholder="10 dígitos"
                 value={formLogin.telefono}
                 onChange={handleLogin} />
 
       </div>
                                                 
       <div class="btn_enviar_formulario_abordo">                        
         <button
             class="btn_enviar"
             disabled={!aceptoPoliticas}
             onClick={login}>
             Iniciar sesión
         </button>
       </div>
 
       <div className='chackbox_text'>
         <input type="checkbox" name="" checked={aceptoPoliticas} onChange={() => setAceptoPoliticas(!aceptoPoliticas)} /> Acepto el <label>Aviso de privacidad</label> y <label>Términos y condiciones</label>. 
       </div>
     </>)}
         
   </>
      
  )
}
